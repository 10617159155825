/* eslint-disable */
/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */

import * as actions from '../actions';
import { fetchGraphQLData } from '../../../graphql';
import Helpers from '../../../helpers';
import { mutation, query } from 'gql-query-builder';
import { cloneDeep, upperFirst, pick } from 'lodash';
import toastr from 'toastr';
import { DriverTypeEnum } from 'generated/graphql';
import { ORDER_GROUP_FRAGMENT, ORDER_WELL_FRAGMENT } from 'javascript/schemas/fragments';
import { handleErrors } from 'error/handleErrors';
import { GraphApiErr } from 'error/GraphApiErr';
import { SENTRY_FEATURE } from 'types/sentryFeatures';

const { CURRENT_CUSTOMER_ID } = Helpers;
const limit = 40;

/**
 * Sets Edit grid status
 *
 * @returns {function}
 */
export const enableEditGrid = (status) => (dispatch) => {
  dispatch({
    type: actions.ENABLE_EDIT_GRID,
    payload: status,
  });
};

/**
 * Sets order details modal
 *
 * @returns {function}
 */
export const setOrderIdModal = (orderId) => (dispatch) => {
  dispatch({
    type: actions.SET_ORDER_ID_MODAL,
    payload: orderId,
  });
};

/**
 * Sets order assign to driver modal
 *
 * @returns {function}
 */
export const setAssignDriverModal = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_ASSIGN_DRIVER_MODAL,
    payload,
  });
};

/**
 * Sets the open state for the new transfer order modal
 *
 * @param {boolean} state whether the modal should be opened
 * @returns {function}
 */
export const setNewTransferOrderModalState = (state) => (dispatch) => {
  dispatch({
    type: actions.SET_NEW_TRANSFER_ORDER_MODAL_STATE,
    payload: state,
  });
};

/**
 * Sets the open state for the schedule transfer order modal
 *
 * @param {boolean} state whether the modal should be opened
 * @returns {function}
 */
export const setScheduleTransferOrderModalState = (state) => (dispatch) => {
  dispatch({
    type: actions.SET_SCHEDULE_TRANSFER_ORDER_MODAL_STATE,
    payload: state,
  });
};

/**
 * Sets order details modal
 *
 * @returns {function}
 */
export const setOrderVerifyModal = (orders) => (dispatch) => {
  dispatch({
    type: actions.SET_ORDER_VERIFY_MODAL,
    payload: orders,
  });
};

/**
 * Sets selected order for bulk assign modal
 *
 * @returns {function}
 */
export const setOrderAssignModal = (orders) => (dispatch) =>
  dispatch({
    type: actions.SET_ORDER_ASSIGN_MODAL,
    payload: orders,
  });

/**
 * Sets selected order for bulk un-assign modal
 *
 * @returns {function}
 */
export const setOrderUnassignModal = (orders) => (dispatch) => {
  dispatch({
    type: actions.SET_ORDER_UNASSIGN_MODAL,
    payload: orders,
  });
};

/**
 * Sets selected order for bulk delete modal
 *
 * @returns {function}
 */
export const setOrderDeleteModal = (orders) => (dispatch) => {
  dispatch({
    type: actions.SET_ORDER_DELETE_MODAL,
    payload: orders,
  });
};

/**
 * Sets is order verified assigned
 *
 * @returns {function}
 */
export const setIsOrderVerified = (status) => (dispatch) => {
  dispatch({
    type: actions.SET_IS_ORDER_VERIFIED,
    payload: status,
  });
};

export const setEndShiftModal = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_END_SHIFT_MODAL,
    payload,
  });
};

export const fetchUpdateShiftPlanner = (payload) => (dispatch) => {
  dispatch({
    type: actions.FETCH_UPDATE_SHIFT_PLANNER,
    payload,
  });
};

export const fetchUpdateCCPlanner = (payload) => (dispatch) => {
  dispatch({
    type: actions.FETCH_UPDATE_SHIFT_PLANNER,
    payload,
  });
};

export const setUpdatedShiftPlannerTask = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_UPDATED_SHIFT_PLANNER_TASK,
    payload,
  });
};

export const removeUpdatedShiftPlannerTaskId = (payload) => (dispatch) => {
  dispatch({
    type: actions.SET_UPDATED_SHIFT_PLANNER_TASK,
    payload: 0,
  });
};
/**
 * List minimal information of order groups for OrderWell, OrderReview and ShiftPlanner
 *
 * @returns {function}
 */
export const fetchOrderGroups =
  (filter = {}, viewName, isOrderWellOpen) =>
  async (dispatch, getState) => {
    try {
      // reset all fetching states initially
      dispatch({
        type: actions.RESET_ORDER_GROUP_COUNT,
      });
      dispatch({
        type: actions.RESET_ALL_FETCHING_ORDER_GROUPS,
      });
      // for loading message , set to true initially
      dispatch({
        type: actions.FETCHING_ORDER_GROUPS,
        payload: { [viewName]: true, orderWell: isOrderWellOpen },
      });

      let totalFetchedOrderListCount = 0;
      let totalFetchedOrderPricingListCount = 0;
      const getFilteredOrderWell = async (offset = 0) => {
        const QUERY = `
          {
            filteredOrderWell(
              limit: ${limit},
              offset: ${offset},
              filter:{
                fromTimestamp: ${filter.fromTimestamp},
                toTimestamp: ${filter.toTimestamp}
              }
              ) {
                collection{
                  ${ORDER_WELL_FRAGMENT}
                }
                metadata {
                  hasMore
                  totalCount
                  total_entries
                }
            }
          }
        `;
        const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));

        const totalCount = response?.data?.filteredOrderWell?.metadata?.totalCount || 0;
        const responseOrderCollection = response?.data?.filteredOrderWell?.collection || [];
        totalFetchedOrderListCount += responseOrderCollection.length;
        if (response.errors && !response.data) {
          if (
            getState().dashboard.fetching[viewName] &&
            totalFetchedOrderListCount < getState().dashboard.orderGroupCount.total
          ) {
            const latestTotal = getState().dashboard.orderGroupCount.total;
            const latestFetched = getState().dashboard.orderGroupCount.fetched;

            // set has fetching errors to true
            dispatch({
              type: actions.SET_ORDER_GROUP_COUNT,
              payload: { fetched: latestFetched, total: latestTotal, hasFetchingErrors: true },
            });
            toastr.error('Error fetching order groups', 'Please try again');
          }
        }

        if (!response?.data?.filteredOrderWell?.collection) {
          throw new Error(
            `Error fetching order groups from ${viewName} with filter ${JSON.stringify(filter)}`
          );
        }

        // append to existing order groups
        dispatch({
          type: actions.FETCH_ORDER_GROUPS,
          payload: {
            ...Helpers.convertArrayToHash(responseOrderCollection, 'orderGroups'),
          },
        });
        // update order group count to display on dashboard
        dispatch({
          type: actions.SET_ORDER_GROUP_COUNT,
          payload: {
            fetched: totalFetchedOrderListCount,
            total: totalCount,
            hasFetchingErrors: false,
          },
        });

        // fetch more if there are more
        if (response?.data?.filteredOrderWell?.metadata?.hasMore && offset === 0) {
          const times = Math.floor(totalCount / limit);

          const promises = Array.from({ length: times }, (_, i) => {
            getFilteredOrderWell((i + 1) * limit);
          });

          await Promise.all(promises);
        }
        return totalFetchedOrderListCount;
      };

      const getFilteredOrderWellPricing = async (offset = 0) => {
        const QUERY = `
        {
          filteredOrderWell(
            limit: ${limit},
            offset: ${offset},
            filter:{
              fromTimestamp: ${filter.fromTimestamp},
              toTimestamp: ${filter.toTimestamp}
            }
            ) {
              collection{
                id
                feesPricingStatus
                pricingMarginStatus
              }
              metadata {
                hasMore
                totalCount
                total_entries
              }
          }
        }
      `;
        const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
        const responseOrderPricingCollection = response?.data?.filteredOrderWell?.collection || [];
        totalFetchedOrderPricingListCount += responseOrderPricingCollection.length;
        // append to existing order groups
        dispatch({
          type: actions.FETCH_ORDER_GROUPS_PRICING,
          payload: {
            ...Helpers.convertArrayToHash(responseOrderPricingCollection, 'orderGroups'),
          },
        });

        // fetch more if there are more
        if (response?.data?.filteredOrderWell?.metadata?.hasMore) {
          const ordersLength = totalFetchedOrderPricingListCount;
          await getFilteredOrderWellPricing(ordersLength);
        }
      };

      await getFilteredOrderWell();

      dispatch(fetchUpdateShiftPlanner(false));
      dispatch(fetchUpdateCCPlanner(false));

      // loading message , set to false after fetching
      dispatch({
        type: actions.FETCHING_ORDER_GROUPS,
        payload: { [viewName]: false, orderWell: false },
      });
      //Since the dispatch is already called to set a value, no need to reset in the same line.
      // // reset the order group count
      // dispatch({
      //   type: actions.SET_ORDER_GROUP_COUNT,
      //   payload: { fetched: 0, total: 0, hasFetchingErrors: false },
      // });

      if (getState().settings.features?.pricingFeature) {
        await getFilteredOrderWellPricing();
      }
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Unable to fetch filteredOrderWell',
        additionalArgs: filter,
        showAlert: false,
        tagName: SENTRY_FEATURE.ORDER_WELL,
      });
    }
  };

/**
 * Update order data when driver is assigned
 *
 * @returns {function}
 */
export const updateOrderOnAssignment = (orderData) => (dispatch, getState) => {
  const orderGroups = JSON.parse(JSON.stringify(getState().dashboard.orderGroups));
  if (orderData.worker?.id) {
    orderGroups[`${orderData.id}_orderGroups`].driver = orderData.worker?.name;
    orderGroups[`${orderData.id}_orderGroups`].driverId = orderData.worker?.id;
    orderGroups[`${orderData.id}_orderGroups`].assignedBy = orderData.assignable?.name;
  } else {
    orderGroups[`${orderData.id}_orderGroups`].driver = null;
    orderGroups[`${orderData.id}_orderGroups`].driverId = null;
  }
  orderGroups[`${orderData.id}_orderGroups`].status = orderData.status;
  orderGroups[`${orderData.id}_orderGroups`].startOnTimestamp = orderData.startOnTimestamp;
  orderGroups[`${orderData.id}_orderGroups`].plannedAt = orderData.startOnTimestamp;
  orderGroups[`${orderData.id}_orderGroups`].busy = false;
  // orderGroups[`${orderData.id}_orderGroups`] = orderData

  dispatch({
    type: actions.UPDATE_ORDER_ON_ASSIGNMENT,
    payload: orderGroups,
  });
};

export const updateLinkedOrders = (orderGroup) => (dispatch, getState) => {
  const updatableStatus = ['unassigned', 'scheduled', 'blocked'];

  const linkedOrderIds =
    orderGroup.loadingOrders[0]?.orderLinks?.map(
      (orderLink) => orderLink.deliveryOrder.orderGroupId
    ) ||
    orderGroup.deliveryOrders[0]?.orderLinks?.map(
      (orderLink) => orderLink.loadingOrder.orderGroupId
    ) ||
    [];

  if (linkedOrderIds.length) {
    linkedOrderIds.forEach((linkedOrderId) => {
      const orderGroups = JSON.parse(JSON.stringify(getState().dashboard.orderGroups));
      const orderStatus = orderGroups[`${linkedOrderId}_orderGroups`].status;
      const updatedOrderGroup = {
        id: linkedOrderId,
        worker: orderGroup.worker,
        assignable: orderGroup.assignable,
        status: orderStatus === 'blocked' ? 'blocked' : 'scheduled',
        startOnTimestamp: orderGroup.startOnTimestamp,
      };

      if (updatableStatus.includes(orderStatus)) {
        updateOrderOnAssignment(updatedOrderGroup)(dispatch, getState);
      }
    });
  }
};

/**
 * Update order in offline mode
 *
 * @param {String} orderGroupId
 * @param {Object} attributes
 *
 * @returns {function}
 */
export const updateOrderOffline = (orderGroupId, attributes) => (dispatch, getState) => {
  const orderGroups = cloneDeep(getState().dashboard.orderGroups);

  const order = orderGroups[`${orderGroupId}_orderGroups`];

  orderGroups[`${orderGroupId}_orderGroups`] = {
    ...order,
    ...attributes,
  };

  dispatch({
    type: actions.UPDATE_ORDER_ON_ASSIGNMENT,
    payload: orderGroups,
  });
};

export const fetchOrderGroup = (id) => async (dispatch) => {
  const QUERY = `
    {
      orderGroup(id: ${id}) {
        ${ORDER_GROUP_FRAGMENT}
      }
    }
  `;
  try {
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));

    if (!response?.data?.orderGroup) {
      console.error('fetchOrderGroup', response);
      throw new Error(`Error fetching order group with id: ${id}`);
    }

    dispatch({
      type: actions.FETCH_ORDER_GROUP,
      payload: Helpers.convertArrayToHash([response.data.orderGroup], 'orderGroups'),
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching order group with id: ${id}`,
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 * List minimal information of order group for OrderWell, OrderReview and ShiftPlanner
 *
 * @returns {function}
 */
export const fetchOrderWellOrders = (id) => async (dispatch) => {
  const QUERY = `
    {
      orderWellOrders(ids: [${id}]) {
        ${ORDER_WELL_FRAGMENT}
      }
    }
  `;

  try {
    const response = await fetchGraphQLData(JSON.stringify({ query: QUERY }));
    if (!response?.data?.orderWellOrders) {
      throw new Error(`Error fetching order group with id: ${id}`);
    }
    dispatch({
      type: actions.FETCH_ORDER_GROUP,
      payload: Helpers.convertArrayToHash(response.data.orderWellOrders, 'orderGroups'),
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching order group with id: ${id}`,
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

export const fetchCustomerData = (id) => async (dispatch) => {
  try {
    const response = await fetchGraphQLData(
      JSON.stringify(
        query({
          operation: 'customer',
          variables: { id: { value: String(id), type: 'ID', required: true } },
          fields: ['id', 'name', 'address'],
        })
      )
    );

    if (!response?.data?.customer) {
      throw new Error(`Error fetching customer with id: ${id}`);
    }

    dispatch({
      type: actions.FETCH_CUSTOMER_DATA,
      payload: response?.data?.customer,
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: `Error fetching customer with id: ${id}`,
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 * Fetch the groups of drivers
 * @returns The list of groups
 */
export const fetchDriverGroups = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.SET_DRIVER_GROUPS_LOADING,
      payload: true,
    });
    const response = await fetchGraphQLData({
      query: `
        query fetchGroups {
          groups: filteredGroups {
            list: collection {
              id
              name
              groupMemberships {
                id
                member: groupable {
                  type: __typename
                  ... on Driver {
                    id
                    name
                  }
                  ... on User {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
    });

    dispatch({
      type: actions.SET_DRIVER_GROUPS_LOADING,
      payload: false,
    });

    if (!response?.data?.groups?.list) {
      throw new Error('Error fetching driver groups');
    }

    dispatch({
      type: actions.SET_DRIVER_GROUPS,
      payload: response?.data?.groups?.list ?? [],
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Error fetching driver groups',
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 * Fetch the groups of hubs
 * @returns The list of groups
 */
export const fetchHubs = () => async (dispatch) => {
  try {
    let hubList = [];

    const getHubList = async (offset = 0) => {
      const hubPayload = `{
          filteredCustomerBranches(
            limit: ${limit},
            offset: ${offset},
            filter: {
              customerId: ${CURRENT_CUSTOMER_ID}
            }) {
              collection {
                id
                name
              }
              metadata {
                hasMore
              }
            }
          }`;

      const { data } = await fetchGraphQLData(JSON.stringify({ query: hubPayload }));

      if (!data?.filteredCustomerBranches?.collection) {
        throw new Error('Error fetching hubs');
      }

      hubList = [...hubList, ...(data?.filteredCustomerBranches?.collection || [])];

      if (data?.filteredCustomerBranches?.metadata?.hasMore) {
        const hubsLength = hubList.length;
        await getHubList(hubsLength);
      }
      return hubList;
    };

    const tempHubData = await getHubList();
    dispatch({
      type: actions.SET_HUBS,
      payload: tempHubData || [],
    });
  } catch (error) {
    handleErrors(error, { fallbackMsg: 'Error fetching hubs', tagName: SENTRY_FEATURE.ORDER_WELL });
  }
};

/**
 * Fetch active shift data from all drivers
 *
 * @returns {function}
 */
export const fetchDriversShiftData = (driverType) => async (dispatch) => {
  try {
    const response = await fetchGraphQLData(
      JSON.stringify(
        query({
          operation: 'drivers',
          variables: {
            filter: {
              value: { driverTypes: driverType },
              type: 'DriverFiltersInput',
            },
          },
          fields: [
            'id',
            'name',
            'status',
            'lastSyncedAtTimestamp',
            {
              resourceSchedule: [
                'id',
                {
                  availability: ['day', 'preferredStartTime', 'preferredEndTime'],
                },
                {
                  unavailability: ['unStartTime', 'unEndTime', 'reason'],
                },
              ],
            },
            {
              activeShift: ['id', 'startOnTimestamp', 'endOnTimestamp'],
            },
            {
              lastShift: ['id', 'startOnTimestamp', 'endOnTimestamp'],
            },
          ],
        })
      )
    );

    const drivers = response.data?.drivers?.filter((i) => i?.status === 'verified');
    if (!response.data.drivers || !drivers) {
      throw new Error('Error fetching drivers shift data');
    }

    dispatch({
      type: actions.FETCH_DRIVERS_SHIFT_DATA,
      payload: drivers,
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Error fetching drivers shift data',
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 *
 * Fetch active shift for common carrier drivers
 * @returns  {function}
 */

export const fetchCcDriversShiftData = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.FETCH_CC_DRIVERS_SHIFT_DATA_LOADING,
      payload: true,
    });
    const response = await fetchGraphQLData(
      JSON.stringify(
        query({
          operation: 'drivers',
          fields: [
            'id',
            'name',
            'status',
            'lastSyncedAtTimestamp',
            {
              activeShift: ['id', 'startOnTimestamp', 'endOnTimestamp'],
            },
            {
              lastShift: ['id', 'startOnTimestamp', 'endOnTimestamp'],
            },
          ],
        })
      )
    );

    dispatch({
      type: actions.FETCH_CC_DRIVERS_SHIFT_DATA_LOADING,
      payload: false,
    });
    const drivers = response.data?.drivers?.filter((i) => i?.status === 'verified');
    if (!response.data.drivers || !drivers) {
      throw new Error('Error fetching drivers shift data');
    }

    dispatch({
      type: actions.FETCH_CC_DRIVERS_SHIFT_DATA,
      payload: drivers,
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Error fetching drivers shift data',
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 * Fetch active shift data from a given driver
 *
 * @returns {function}
 */
export const fetchDriverShiftData = (id) => async (dispatch) => {
  if (!id) return;

  const response = await fetchGraphQLData(
    JSON.stringify(
      query({
        operation: 'driver',
        variables: {
          id: { value: String(id), type: 'ID', required: true },
        },
        fields: [
          'id',
          'name',
          'status',
          'lastSyncedAtTimestamp',
          {
            activeShift: [
              'id',
              'startOnTimestamp',
              'endOnTimestamp',
              {
                shiftCompartmentInstances: [
                  'id',
                  {
                    retainInventory: [
                      'volume',
                      {
                        product: ['id', 'name', 'code', 'shortName', 'colorCode'],
                      },
                    ],
                  },
                  {
                    compartment: ['id', 'name'],
                  },
                ],
              },
              {
                shiftDeliveryUnitsAssets: [
                  'id',
                  {
                    deliveryAsset: ['id', 'name', 'licensePlateNumber', 'category'],
                  },
                ],
              },
            ],
          },
          {
            lastShift: ['id', 'startOnTimestamp', 'endOnTimestamp'],
          },
        ],
      })
    )
  );

  if (!response.data) {
    const errorInfo = { code: 'ERROR', message: 'Could not fetch driver shift data' };

    const graphApiErr = new GraphApiErr(errorInfo, response);

    handleErrors(graphApiErr);

    return;
  }

  const dataDriver = response.data.driver;

  if (!response.data?.driver) {
    const errorInfo = { code: 'ERROR', message: 'Could not fetch driver data' };

    const graphApiErr = new GraphApiErr(errorInfo, response);

    handleErrors(graphApiErr);

    return;
  }

  const driver = {
    ...dataDriver,
    products: dataDriver.activeShift?.shiftCompartmentInstances?.reduce((products, instance) => {
      instance.retainInventory?.forEach((retain) => {
        products[retain.product.id] = products[retain.product.id] || {
          ...retain.product,
          volume: 0,
          compartments: {},
        };

        products[retain.product.id].volume += Number(retain.volume);
        products[retain.product.id].compartments[instance.compartment.name] =
          (products[retain.product.id].compartments[instance.compartment.name] || 0) +
          Number(retain.volume);
      });

      return products;
    }, {}),
  };

  dispatch({
    type: actions.FETCH_DRIVER_SHIFT_DATA,
    payload: driver,
  });
};

export const deleteOrderFromLocalForDashboard = (id) => (dispatch) => {
  if (!id) return;

  dispatch({
    type: actions.DELETED_ORDERS,
    payload: `${id}_orderGroups`,
  });
};

/**
 * Destroy delivery and loading orders
 *
 * @returns {function}
 */
export const deleteOrders =
  (deliveryOrderIds = [], loadingOrderIds = [], transferOrderIds = []) =>
  async (dispatch) => {
    try {
      const mutationBuilder = (type) => `
      mutation delete${upperFirst(type)}Order($id: ID!) {
        delete${upperFirst(type)}Order(input: { id: $id }) {
          orderGroup {
            ${ORDER_GROUP_FRAGMENT}
          }
        }
      }
    `;
      const loadingMutation = mutationBuilder('loading');
      const deliveryMutation = mutationBuilder('delivery');
      const transferMutation = mutationBuilder('transfer');

      let loadingResponse;
      let deliveryResponse;
      let transferResponse;

      if (deliveryOrderIds?.length > 0) {
        deliveryResponse = await fetchGraphQLData({
          query: deliveryMutation,
          variables: { id: deliveryOrderIds?.[0] },
        });
        if (deliveryResponse) {
          const deletedId = deliveryResponse?.data?.deleteDeliveryOrder?.orderGroup?.id || '';
          toastr.success(`Successfully deleted orders with id: ${deletedId}`);
        } else {
          toastr.error(`Failed to delete order.`);
        }
      }

      if (loadingOrderIds?.length > 0) {
        loadingResponse = await fetchGraphQLData({
          query: loadingMutation,
          variables: { id: loadingOrderIds?.[0] },
        });
        if (loadingResponse) {
          const deletedId = loadingResponse?.data?.deleteLoadingOrder?.orderGroup?.id || '';
          toastr.success(`Successfully deleted orders with id: ${deletedId}`);
        } else {
          toastr.error(`Failed to delete order.`);
        }
      }

      if (transferOrderIds?.length > 0) {
        transferResponse = await fetchGraphQLData({
          query: transferMutation,
          variables: { id: transferOrderIds?.[0] },
        });
        if (transferResponse) {
          const deletedId = transferResponse?.data?.deleteTransferOrder?.orderGroup?.id || '';
          toastr.success(`Successfully deleted orders with id: ${deletedId}`);
        } else {
          toastr.error(`Failed to delete order.`);
        }
      }

      const orderGroup =
        loadingResponse?.data?.deleteLoadingOrder?.orderGroup ||
        deliveryResponse?.data?.deleteDeliveryOrder?.orderGroup ||
        transferResponse?.data?.deleteTransferOrder?.orderGroup;

      // if (!orderGroup.id) {
      //   dispatch({
      //     type: actions.DELETED_ORDERS,
      //     payload: `${orderGroup.id}_orderGroups`
      //   })
      // } else {
      //   dispatch({
      //     type: actions.FETCH_ORDER_GROUP,
      //     payload: Helpers.convertArrayToHash(
      //       [{ id: orderGroup?.id }],
      //       'orderGroups'
      //     )
      //   })
      // }
      if (orderGroup?.id) {
        dispatch({
          type: actions.DELETED_ORDERS,
          payload: `${orderGroup.id}_orderGroups`,
        });
      }
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Error deleting orders',
        tagName: SENTRY_FEATURE.ORDER_WELL,
      });
    }
  };

/**
 * Change assignment data of a task
 *
 * @returns {function}
 */
export const assignTask =
  (orderGroupId, startTime = null, driverId = null, isDriverLinkable = false) =>
  async (dispatch, getState) => {
    try {
      const worker = pick(
        getState().dashboard.driversShiftData.find((driver) => driver.id === driverId),
        ['id', 'name']
      );

      updateOrderOffline(orderGroupId, {
        worker,
        status: 'scheduled',
        startOnTimestamp: startTime,
        busy: true,
      })(dispatch, getState);

      const mutation = `
      mutation assignTask($id: ID!, $startTime: String, $driverId: ID, $isDriverLinkable: Boolean) {
        changeOrderGroupAssignment(input: {
          orderGroupId: $id,
          startTime: $startTime,
          driverId: $driverId,
          isDriverLinkable: $isDriverLinkable
        })
        {
          orderGroup {
            ${ORDER_GROUP_FRAGMENT}
          }
        }
      }
    `;

      const response = await fetchGraphQLData(
        JSON.stringify({
          operationName: 'assignTask',
          query: mutation,
          variables: {
            id: Number(orderGroupId),
            startTime: startTime ? String(startTime) : null,
            driverId,
            isDriverLinkable,
          },
        })
      );

      const orderGroup = response?.data?.changeOrderGroupAssignment?.orderGroup;

      if (orderGroup) {
        updateOrderOnAssignment(orderGroup)(dispatch, getState);
        if (!orderGroup?.worker) {
          toastr.error('Order not assigned to driver');
        } else if (isDriverLinkable) {
          updateLinkedOrders(orderGroup)(dispatch, getState);
          toastr.success('Delivery and linked load orders are assigned successfully');
        } else {
          toastr.success('Order assigned to driver');
        }
      } else if (response.error) {
        toastr.error(response.error.message);
      }
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Error assigning task',
        tagName: SENTRY_FEATURE.ORDER_WELL,
      });
    }
  };

/**
 * Change startOnTimestamp of an order group
 *
 * @returns {function}
 */
export const updateOrderScheduledAt =
  (orderGroupId, datetime = null) =>
  async (dispatch, getState) => {
    try {
      updateOrderOffline(orderGroupId, {
        startOnTimestamp: datetime,
      })(dispatch, getState);

      const mutation = `
      mutation UpdateOrderScheduledAt($id: ID!, $datetime: Int) {
        updateOrderGroup(input: {orderGroupAttributes: {id: $id, startOnTimestamp: $datetime}}) {
          orderGroup {
            ${ORDER_GROUP_FRAGMENT}
          }
        }
      }
    `;
      const response = await fetchGraphQLData(
        JSON.stringify({
          operationName: 'UpdateOrderScheduledAt',
          query: mutation,
          variables: {
            id: Number(orderGroupId),
            datetime,
          },
        })
      );

      const orderGroup = response?.data?.updateOrderGroup?.orderGroup;

      if (response.error) {
        toastr.error(response.error.message);
      } else if (orderGroup) {
        updateOrderOnAssignment(orderGroup)(dispatch, getState);
      }
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Error on update order schedule',
        tagName: SENTRY_FEATURE.ORDER_WELL,
      });
    }
  };

/**
 * Unassign driver from a task
 *
 * @returns {function}
 */
export const unassignDriver =
  (orderGroupId) =>
  async (...args) => {
    try {
      const mutation = `
      mutation {
        changeOrderGroupAssignment(input: {
          orderGroupId: ${Number(orderGroupId)}
        })
        {
          orderGroup {
            ${ORDER_GROUP_FRAGMENT}
          }
        }
      }
    `;

      const response = await fetchGraphQLData(JSON.stringify({ query: mutation }));
      const orderGroup = response?.data?.changeOrderGroupAssignment?.orderGroup;

      if (response.error) {
        toastr.error(response.error.message);
      } else if (orderGroup) {
        toastr.success('Order is unassigned');
        setAssignDriverModal(null)(...args);
        updateOrderOnAssignment(orderGroup)(...args);
      } else {
        toastr.error("Order can't be unassigned");
      }
    } catch (error) {
      handleErrors(error, {
        fallbackMsg: 'Unable to unassign driver',
        tagName: SENTRY_FEATURE.ORDER_WELL,
      });
    }
  };

export const updatePriority = (orderGroupId, priority) => async (dispatch, getState) => {
  try {
    const mutation = `mutation{updateOrderGroup(input: {orderGroupAttributes: {id: ${orderGroupId}, priority: ${priority}}}){orderGroup{${ORDER_GROUP_FRAGMENT}}}}`;
    const response = await fetchGraphQLData(JSON.stringify({ query: mutation }));
    const orderGroup = response?.data?.updateOrderGroup?.orderGroup;

    const orderGroupState = JSON.parse(JSON.stringify(getState().dashboard.orderGroups));

    if (Object.keys(orderGroupState).includes(`${orderGroupId}_orderGroups`)) {
      orderGroupState[`${orderGroupId}_orderGroups`].priority = orderGroup.priority;
      dispatch({
        type: actions.UPDATE_ORDER_ON_ASSIGNMENT,
        payload: orderGroupState,
      });
    }

    if (response.data) {
      toastr.success('Priority updated successfully');
    } else if (response.error) {
      toastr.error(response.error.message);
    }
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Unable to update priority',
      additionalArgs: { orderGroupId, priority },
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

export const pusherOrderWellUpdate = (payload) => (dispatch) => {
  dispatch({
    type: actions.PUSHER_ORDERWELL_UPDATE,
    payload,
  });
};

export const pusherDriverShiftStarted = (payload) => (dispatch) => {
  dispatch({
    type: actions.PUSHER_DRIVER_SHIFT_STARTED,
    payload,
  });
};

export const pusherDriverInventoryUpdate = (payload) => (dispatch, getState) => {
  dispatch({
    type: actions.PUSHER_DRIVER_INVENTORY_UPDATED,
    payload,
  });
};

export const pusherDriverShiftEnded = (payload) => (dispatch) => {
  dispatch({
    type: actions.PUSHER_DRIVER_SHIFT_ENDED,
    payload,
  });
};

/**
 * Fetch the groups of common carriers
 * @returns The list of common carriers
 */
export const fetchCommonCarriers = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.SET_COMMON_CARRIERS_LOADING,
      payload: true,
    });
    const response = await fetchGraphQLData(
      JSON.stringify(
        query({
          operation: 'ccTenants',
          fields: [
            'id',
            'name',
            'address',
            'carrierTenantId',
            // This isnot used for now but we can use it in future
            // {
            //   activeShift: ['id'],
            // },
            // { lastShift: ['id', 'startOnTimestamp', 'endOnTimestamp'] },
            { site: ['address', 'city'] },
            { carrierTenant: ['name', { adminUsers: ['name', 'phone'] }] },
          ],
        })
      )
    );
    dispatch({
      type: actions.SET_COMMON_CARRIERS_LOADING,
      payload: false,
    });
    dispatch({
      type: actions.SET_COMMON_CARRIERS,
      payload: response?.data?.ccTenants || [],
    });
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Unable to fetch common carriers',
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
};

/**
 * Send bulk order email to driver
 * @returns "success" if email sent successfully
 */
export const sendBulkOrderEmailToDriver = (orderIds) => async (dispatch) => {
  dispatch({
    type: actions.SET_SENDING_EMAIL_TO_DRIVER,
    payload: true,
  });
  try {
    const response = await fetchGraphQLData(
      JSON.stringify(
        mutation({
          operation: 'sendBulkOrderEmailToDriver',
          variables: {
            input: {
              type: 'SendBulkOrderEmailToDriverInput',
              required: true,
              value: {
                orderGroupIds: orderIds,
              },
            },
          },
          fields: ['success'],
        })
      )
    );

    // handle response
    if (response?.data?.sendBulkOrderEmailToDriver?.success) {
      toastr.success('Email sent successfully');
      dispatch({
        type: actions.SET_SENDING_EMAIL_TO_DRIVER,
        payload: false,
      });
      return 'success';
    } else {
      toastr.error('Error sending email');
    }
  } catch (error) {
    handleErrors(error, {
      fallbackMsg: 'Unable to send bulk order emails to driver',
      tagName: SENTRY_FEATURE.ORDER_WELL,
    });
  }
  dispatch({
    type: actions.SET_SENDING_EMAIL_TO_DRIVER,
    payload: false,
  });
};
